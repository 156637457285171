//Device Status
.device-status{
	display: flex;
    flex-direction: column;
    padding-top: 1rem;
	&-ck{
		height: 200px;
		margin: auto 0;
	}
	&-group{
		display: flex;
		justify-content: space-between;
		padding-top: 1.75rem;
        margin: auto;
        width:280px;
        max-width: 100%;
	}
	&-data{
		> .icon{
			font-size: 1.5rem;
		}
		.title{
			font-size: 12px;
			color: $base-light;
			margin-bottom: 0.5rem;
		}
		.amount{
			font-size: 1.125rem;
			color: $base-color;
			font-weight: $fw-medium;
		}
		.change{
			margin-left: -.125rem;
		}
	}
}


//Traffic Channel
.traffic-channel{
    margin-top: 1rem;
	&-ck{
		height: 44px;
		width: 130px;
		margin-top: -4px;
		margin-bottom: -4px;
	}
	&-doughnut-ck{
		height: 160px;
	}
	&-group{
		display: flex;
		flex-wrap: wrap;
        padding-top: 1.75rem;
        margin-left: auto !important;
        margin-right: auto !important;
        width:320px;
        max-width: 100%;
	}
	&-data{
		width: 50%;
		.title{
			display: flex;
			align-items: center;
			color:$base-light;
			font-size: $fx-sz-12;
			.dot{
				margin-right: 0.5rem;
			}
		}
		.amount{
			font-size: 1.125rem;
            color:$base-color;
			margin-left: 1.25rem;
			small{
				font-size: $fx-sz-12;
				color: $base-light;
			}
		}
	}
}

@include media-breakpoint-down(sm){
    .traffic-channel{
        &-ck{
            width: 100%;
        }
        &-table{
            .nk-tb-item{
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid $border-light;
                padding: 1.125rem 0;
            }
            .nk-tb-col{
                border-bottom: none !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
            .nk-tb-channel{
                width: 50%;
            }
            .nk-tb-sessions{
                width: 25%;
            }
            .nk-tb-prev-sessions{
                display: none;
            }
            .nk-tb-change{
                width: 25%;
            }
            .nk-tb-trend{
                width: 100%;
				padding-left: 1.25rem;
            }
        }
    }
}
@media (min-width:1540px) and (max-width:1800px){
    .traffic-channel{
        &-data{
            .amount{
                font-size: 1rem;
            }
        }
    }
}
//Analytic Data
.analytic-data{
	&-group{
        display: flex;
        flex-wrap: wrap;
	}
	.title{
		font-size: $fx-sz-12;
		font-weight: $fw-medium;
		color: $base-light;
		margin-bottom: 0.375rem;
		span{
			font-weight: $fw-normal;
		}
	}
	.amount{
		color: $base-color;
		font-size: 1.5rem;
		line-height: 1.2;
		margin-bottom: 0.25rem;
		&-sm{
			font-size: 1.125rem;
		}
	}
	.change{
		font-size: $fx-sz-13;
		margin-left: -0.125rem;
	}
	.subtitle{
		font-size: $fx-sz-12;
		color: $base-light;
	}
}

@media (min-width:1540px) and (max-width:1800px){
    .analytic-data{
        .amount{
            font-size: 1.25rem;
            line-height: 1.75rem;
            &-sm{
                font-size: 1.125rem;
            }
        }
    }
}

//Analytic Overview
.analytic-ov{
	&-data{
		width: 50%;
	}
	&-ck{
		height: 175px;
		margin-top: 2rem;
	}
}

@media (min-width:480px){
    .analytic-ov{
        &-data{
            width: 25%;
        }
    }
}

//Analytic User
.analytic-au{
	&-data{
		width: 33.3333%;
	}
	&-ck{
		height: 170px;
		margin-top: 2.25rem;
	}
}

//Analytic Performance
.analytic-wp{
	&-data{
		display: flex;
        justify-content: space-between;
        align-items: flex-end;
	}
	&-ck{
		height: 36px;
	}
	&-graph{
        width: 150px;
        margin-top: 0.125rem;
        margin-bottom: 0.125rem;
		.title{
			margin-bottom: 0;
		}
	}
	&-text{
		text-align: right;
		margin-left: .5rem;
	}
}
@media (min-width:420px) and (max-width:767px){
    .analytic-wp{
        &-graph{
            width: 60%;
        }
    }
}
@media (min-width:1540px) and (max-width:1800px){
    .analytic-wp{
        &-graph{
            width: 120px;
        }
    }
}


//chart label
.chart-label{
	font-size: $fx-sz-12;
	color:$base-light;
	&-group{
		margin-top: 0.5rem;
		display: flex;
		justify-content: space-between;
	}
}

//analytics Map
.analytics-map{
	.vector-map{
		height: 160px;
		margin: 1rem 0;
	}
	&-data{
		font-size: .875rem;
		td{
			padding: .25rem 0;
		}
		.country{
			color:$base-light;
		}
		.amount{
			color:$base-color;
			text-align: right;
		}
		.percent{
			text-align: right;
			color:$base-light;
		}
		&-list{
			width: 100%;
			margin-bottom: -0.25rem;
		}
	}
}