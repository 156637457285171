.card {
    box-shadow: 0px 1px 3px 0px rgba($base-color, 0.05);

    .card {
        box-shadow: 0px 1px 6px 1px rgba($base-color, 0.10);
    }

    + .card:not(.card .card + .card) {
        margin-top: $gutter-width;
    }

    + .nk-block-head {
        padding-top: 2.5rem;
    }

    &-full {
        height: 100%;
    }

    &.is-dark {
        background: $accent-dark;
    }

    &-bordered {
        border: 1px solid $card-bordered-color;

        &.is-dark {
            border-color: $accent-dark;
        }

        &.dashed {
            border-style: dashed;
        }
    }

    &-inner {
        padding: $card-spacer-x;

        &-sm {
            padding-top: .75rem;
            padding-bottom: .75rem;
        }

        &-group {
            .card-inner {
                &:not(:last-child) {
                    border-bottom: 1px solid $card-bordered-color;
                }
            }
        }
    }

    &-head {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: -.25rem -0.5rem 1rem;

        > * {
            padding: 0.25rem .5rem;
        }

        > .title:only-child {
            width: 100%;
        }
    }

    &-title {
        &-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .card-title {
                margin-bottom: 0
            }

            &:only-child,
            .card-search {
                margin-top: -.25rem;
                margin-bottom: -.25rem;
            }
        }

        h6.title:not(:last-child) {
            margin-bottom: 0.25rem;
        }

        p {
            font-size: $fx-sz-12;
            color: $base-light;

            .icon {
                line-height: inherit;
                vertical-align: middle;
            }
        }

        &-sm {
            .title {
                font-size: $fx-sz-14;
            }
        }

        small {
            font-weight: $fw-medium;
            color: $base-light;
        }

        .is-dark & {
            color: $white;

            .title {
                color: inherit;
            }
        }
    }

    &-subtitle {
        color: $base-light;
        font-family: $base-font-family;
        font-weight: normal;
    }

    &-tools {
        &-nav {
            display: flex;
            margin: -6px -10px -5px;

            li {
                padding: 0 10px;

                a {
                    position: relative;
                    color: $base-light;
                    padding: 10px 0;
                    display: block;
                    line-height: 1;

                    &:before {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 2px;
                        content: '';
                        background: $accent-color;
                        opacity: 0;
                    }

                    &:hover,
                    &.active {
                        color: $base-text;
                    }

                    &.active {
                        &::before {
                            opacity: 1;
                        }
                    }
                }

                &.active {
                    a {
                        color: $base-text;

                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &.me-n1 {
            margin-right: -0.5rem !important;
        }
    }

    &-hint {
        font-size: 1rem;
        color: $border-color;
    }

    &-tools {
        display: flex;
    }

    &-note {
        font-size: 12px;
        line-height: 1.125rem;
        color: $base-light;
        display: flex;

        .icon {
            font-size: 1.2em;
            line-height: 1.125rem;
            margin-right: 0.5rem;
            display: inline-block;
        }
    }
}

@include media-breakpoint-down(md) {
    .card-head {
        &.ui-v2 {
            .card-tools {
                width: calc(100% + 20px);
                margin: 0 -10px;
                padding-top: .5rem;

                &-nav {
                    padding: 0 .75rem;
                    border-bottom: 1px solid $border-light;

                    li {
                        a {
                            padding-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

    .card-stretch {
        margin-left: - $card-stretch-gap-x;
        margin-right: - $card-stretch-gap-x;
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0 !important;
    }
}

@include media-breakpoint-up(sm) {
    .card {
        &-inner {
            padding: $card-spacer-sm-x;

            &-sm {
                padding-top: .75rem;
                padding-bottom: .75rem;
            }

            &-md {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }

        &-header {
            padding-left: $card-spacer-sm-x;
            padding-right: $card-spacer-sm-x;
        }
    }
}

@include media-breakpoint-up(md) {
    .card {
        &-inner-lg {
            padding: $card-spacer-large;
        }

        &-inner-xl {
            padding: ($card-spacer-large * 1.5);
        }
    }
}

